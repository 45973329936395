import React from "react";
import { useForm } from "react-hook-form";
import { sendForm } from 'emailjs-com';
import apiKeys from "../apikeys";


export default function Contact(props) {
  const { register, handleSubmit, errors } = useForm();
  //const onSubmit = data => console.log(data);
   
   
  const onSubmit = (data) => {
    console.log(data);
   //"gmail", apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID
   sendForm( "mmazurek@mutualstreet.ca", apiKeys.TEMPLATE_ID, '#contactForm', apiKeys.USER_ID)
     .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
     }, function(error) {
       console.log('FAILED...', error);
     });
 }

  if(props.data){
   var name = props.data.name;
   var city = props.data.address.city;
   var phone= props.data.phone;
   var email = props.data.email;
 }
 
  return (
   <section id="contact">

   <div className="row section-head">

      <div className="two columns header-col">

         <h1><span>Get In Touch.</span></h1>

      </div>

      <div className="ten columns">

            <p className="lead">Contact us</p>

      </div>

   </div>

   <div className="row">
   <div className="eight columns">

   <form id="contactForm" name="contactForm" onSubmit = {handleSubmit(onSubmit)} >
   <fieldset>

      <div>
         <label htmlFor="contactName">Name <span className="required">*</span></label>
         <input defaultValue="" size="35" id="contactName" name="contactName" ref={register({ required: true })}/>
         { errors.contactName && errors.contactName.type === "required" && (<p>This field is required *</p>) }
      </div>
      
      <div>
         <label htmlFor="contactEmail">Email <span className="required">*</span></label>
         <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" ref={register({ required: "This field is required *", pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address"
          } })} />
         { errors.contactEmail && ( <p>{ errors.contactEmail.message }</p>) }
      </div>

      <div>
         <label htmlFor="contactMessage">Message <span className="required">*</span></label>
         <textarea cols="50" rows="15" id="contactMessage" name="contactMessage" ref={register({ required: true })}></textarea>
         { errors.contactMessage && errors.contactMessage.type === "required" && (<p>This field is required *</p>) }
      </div>

      <div>
         <button className="submit">Submit</button>
         <span id="image-loader">
            <img alt="" src="images/loader.gif" />
         </span>
      </div>
   </fieldset>
   </form>

      <div id="message-warning"> Error boy</div>
         <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!<br />
         </div>
      </div>
            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">
					   <p className="address">
						   {name}<br />
						   {city} <br />
						   <span>{phone}</span> <br />
                     <span>{email}</span>
					   </p>
				   </div>
               <div className="contact-us-logo">
                  <img src="../images/logo.png.jpeg" width="300px"></img>
               </div>
            </aside>
      </div>
   </section>
  );
}
