import React, { Component } from 'react';

class About extends Component {
  
  render() {

    return (
      <section id="about">  
      <div className="row">
         <div className="twelve columns main-col">
            <h2>ABOUT US</h2>
            <p><b>Talent Co. | Talent Management | Talent Acquistion | Headhunting | Recruitment | Executive Search</b></p>
           
            <p>Mutualstreet; Founded on the very premise, of not agreeing with the traditional internal & external 'agency & 'corporate' recruitment ways. </p>
            
            <p><b>Genuine</b>, in our approach. <b>Honest</b>, in all of our communication. <b>Upfront</b>, with all information. <b>Integral</b>, by means of business.</p>
            <p>Recruitment is not sales, it's not a numbers game.</p> 
            <p>Mutualstreet works entirely different than your traditional staffing & recruitment agency model, even an internal HR or Talent Acquisition / Recruitment Department.</p> 
            <p>Firstly, no job boards. No database. No posting of positions. There are no Junior or inexperienced Recruiters here. No salesy 'Account Managers' that don't speak to the candidates themselves. There are no mass emails getting blasted, no mass communication whatsoever and no, there are no numbers or parameters for us to hit.</p>
            <p><b>Unlike</b> an agency and most internal recruiters, we also do not post our open positions, we do not visit job boards, looking for active job seekers and we do not use a database of previously or currently active job seekers. We don’t mass email, we do not template our messaging; we do not ask for referrals. </p>
            
            <p>If Glassdoor were to evaluate the recruiting profession, recruiters (external or internal) would have little to no stars – almost everyone has had a bad experience with a recruiter at somepoint in their career; whether it's an introductory message that doesn't really apply to your skillset, too many messages to begin with, getting cold called at work, not being provided with any updates, the list of these bad experiences goes on and on.</p> 
            <p>At Mutualstreet, we partner with our clients; companies and professionals alike. They are industry leading companies & working professionals.</p>
          
            <p>With some <b>50+</b> personally written recommendations on LinkedIn, we are consistently delivering to not just our client <b>partners</b> but to whom are proven <b>industry leading working professionals.</b></p>
            <p> <b>Contingency based. No upfront fees. No retainer. Service is backed by Our Guarantee. </b></p>
            <p> Mutual Street Management partners with industry leading companies and industry leading professionals, across the Tech | Gaming | Animation | VFX industries. </p>
            <p>Are you curious about a career change? Are you looking to actually hire? Do you want more out of your current career? Are you fed up with the quality of candidates from your own HR? Do you have a tough to fill position? Are you just not getting the search results that you thought of? Are you looking for Executive Search?  </p>
            <p> Vancouver | Toronto | Montreal | Remotely | Canada </p>
            
            
            
  
         
          </div>
      </div>

   </section>
    );
  }
}

export default About;
