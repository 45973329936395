import React, { Component } from 'react';
import FsLightbox from 'fslightbox-react';


class Portfolio extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      slide:1
    };
  }

  render() {
    
    return (
      <section id="portfolio">

        <div className="row">

          <div className="twelve columns collapsed">

            <h1>Message from the Founder, Matthew R. Mazurek</h1>
          
                <p>"Mutual Street Management works on four foundations – Transparency, Trust, Honesty & Integrity. We aren't like the rest of the agencies and we aren’t like the rest of most recruiters, internal or external. It's not sales for us, and it’s not a numbers game. It's not pressure, from another manager themselves. It's transparency through the process, it's a level of trust we establish through our communication, it’s the honesty that comes from the very beginning and it’s our integrity that supports you all the way through. We consult. We advise. We select - trust me when I say, Mutual Street Management does this business different, in every right way."</p>
                
                <p className="portfolio-right">--Matthew R. Mazurek | Head of Talent | Mutual Street Management </p>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              <div className="col left-col">
                <div key='a' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="a" src='images/portfolio/image_20201021_1.jpeg' onClick={() => this.setState({toggler:!this.state.toggler,slide:1})} />
                  </div>
                </div>
              
                <div key='b' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="b" src='images/portfolio/t6.jpg' onClick={() => this.setState({toggler:!this.state.toggler,slide:6})} />
                  </div>
                </div>

                <div key='c' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="c" src='images/portfolio/t7.jpg' onClick={() => this.setState({toggler:!this.state.toggler,slide:7})} />
                  </div>
                </div>
              </div>

              <div className="col center-col">
                  <div key='d' className="columns portfolio-item">
                    <div className="item-wrap">
                      <img alt="d" src='images/portfolio/t4.jpg' onClick={() => this.setState({toggler:!this.state.toggler,slide:4})} />
                    </div>
                  </div>
                
                <div key='e' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="e" src='images/portfolio/t5.jpg' onClick={() => this.setState({toggler:!this.state.toggler,slide:5})} />
                  </div>
                </div>
              </div>

              <div className="col right-col">
                <div key='f' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="f" src='images/portfolio/t2.jpg' onClick={() => this.setState({toggler:!this.state.toggler,slide:2})} />
                  </div>
                </div>

                <div key='g' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="g" src='images/portfolio/t3.jpg' onClick={() => this.setState({toggler:!this.state.toggler,slide:3})} />
                  </div>
                </div>

                <div key='h' className="columns portfolio-item">
                  <div className="item-wrap">
                    <img alt="h" src='images/portfolio/image_20201021_2.jpeg' onClick={() => this.setState({toggler:!this.state.toggler,slide:8})} />
                  </div>
                </div>
              </div>
            </div>
          <div>
              <FsLightbox
                toggler={this.state.toggler}
                sources={[
                'images/portfolio/image_20201021_1.jpeg',
                'images/portfolio/t2.jpg',
                'images/portfolio/t4.jpg',
                'images/portfolio/t5.jpg',
                'images/portfolio/t6.jpg',
                'images/portfolio/t7.jpg',
                'images/portfolio/t3.jpg',
                'images/portfolio/image_20201021_2.jpeg'
                ]}
                type="image"
                slide={this.state.slide}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
