import React, { Component } from 'react';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'

class Resume extends Component {
  
  render() {

    return (
      <section id="resume">
        <div className="row">
          <div className="twelve columns collapsed">
            <Rater rating={5} total={5} interactive={false} />
            <h1>Talent Management | Acquisition | Recruitment | Headhunting | Executive Search  </h1>
                                    <p> We Consult. | We Advise. | We Select. </p>
          </div>
        </div>
   </section>
    );
  }
}

export default Resume;
