import React, { Component } from 'react';

class Partner extends Component {
  
  render() {

    return (
      <section id="partner">
        <div className="row">
          <div className="twelve columns main-col partner">
            <img src="images/mutual-street_logo_color.png" />
          </div>
        </div>
   </section>
    );
  }
}

export default Partner;
