import React, { Component } from 'react';

class Expertise extends Component {
  
  render() {

    return (
      <section id="expertise">
        <div className="row">
          <div className="twelve columns main-col">
            <h2>AREAS OF EXPERTISE</h2>
            <p><b>Tech | </b> All levels of Production; <b> Web | Desktop | Mobile | Applications</b> | SaaS | PaaS | Software Development/Engineering | Development Operations (DevOps) | Security Operations (SecOps) | Data Engineering & Data Analysis | Software Engineering in Test (SDE IT) | Product Design, Product Management & Product Marketing | Marketing Operations, Demand Generation, Channel Marketing, Lifecycle Marketing, Brand Marketing, Digital Marketing & Sales Growth | Finance | Sales | Customer Enablement | Customer Success -- <b>Executive Leadership.</b></p>
            <p><b>Gaming | </b> All levels of Production; <b> Console | PC | Mobile | VR / AR / MR</b> | Producing. Product Management. Product Marketing. User Acquisition. Lifecycle. Brand. Game Engineering & Development, Programming. Game Design (Systems, Level, Mission, Quest, Economy, UI/UX), Live Operations, Art (Concept, 2D, 3D, Environment, Character), Animation, VFX, Data Analysis/Engineering, QA/Testing, Player Support / Player Success -- <b>Executive Leadership.</b></p>
            <p><b>Animation | </b> All levels of Production; <b> 2D | 3D | CG</b> | Production Management | Production Coordination | Directing | Writing | Storyboarding | Art |Design | Rigging | Studio Operations | HR | Accounting | Technical Direction | 
             Production -- <b>Executive Leadership</b></p>
            <p><b>VFX | </b> All levels of Production; <b>Film | Television</b> | Production Management | Production Coordination | Art | Modeling | Animation | Lighting | Technical Direction -- <b>Executive Leadership.</b> </p>    
    
          </div>
        </div>
   </section>
    );
  }
}

export default Expertise;
